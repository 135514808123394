<template>
<div>
    <!-- ***** Breadcrumb Area Start ***** -->
    <section class="section breadcrumb-area bg-overlay d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Breamcrumb Content -->
                    <div class="breadcrumb-content text-center">
                        <h2 class="text-white text-capitalize">About Us</h2>
                        <h6 class="text-white mt-3">
                            "If you want to go fast, go alone. If you want to go far, go together!" 🚀
                        </h6>
                        <ol class="breadcrumb d-flex justify-content-center mt-4">
                            <li class="breadcrumb-item"><a href="/" target="_self">Home</a></li>
                            <li class="breadcrumb-item active">About us</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ***** Breadcrumb Area End ***** -->

    <section class="section team-area team-style-two overflow-hidden ptb_50">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p>
                        <span style="font-size:2em">E</span>arn Relai is the first leading wallet company in the world to offer auto stake rewards on user holdings, making it easy for users to grow their digital assets effortlessly.
                        As a company, we emphasize long-term security, reliability, and convenience. Our key products and services reflect that vision. Altogether, Relai offers a secure mobile wallet (Relai Wallet), and  also offers a high-end cold storage wallet used for staking. (Earn Relai staking vault)
                    </p>
                    <p class="my-4 text-center" style="font-weight: bold;font-size: 1.3em">
                        “We make it easy for everyone to own and use cryptocurrencies.All the coins you hold, trading, Defi and more conveniently all in one app.”
                    </p>
                    <p class="mb-3">
                        We aim to be a one-stop shop for everything crypto. Relai is an all-in-one blockchain technology infrastructure provider, which includes Relai Wallet, its flagship software wallet; and Earn Relai, its crypto auto stake wallet. Among its structures are, which covers wallet as a service (WaaS), staking as a service (StaaS), and a multi-signatory decentralized finance (DeFi) platform, which is specifically catering to the needs of institutional clients.
                        Earn Relai is headquartered in Switzerland, with offices in Hong Kong, USA, Dubai and UK.
                    </p>
                    <p>
                        For more information, please visit <a href="https://earnrelai.com" target="_blank">https://earnrelai.com</a>
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section class="section team-area team-style-two overflow-hidden ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <!-- Section Heading -->
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="section-heading text-center mt-lg-5">
                                <h2 class="text-capitalize">Relai's leadership</h2>
                                <p class="d-none d-sm-block mt-4">
                                    Our leadership team is composed of early bitcoin adopters and accomplished tech entrepreneurs.
                                </p>
                                <p class="d-block d-sm-none mt-4">
                                    Our leadership team is composed of early bitcoin adopters and accomplished tech entrepreneurs.
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-8">

                            <div class="row">
                                <div class="col-12 col-md-3">
                                    <!-- Single Team -->
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-1.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-9">
                                    <div class="img-txt">
                                        <!-- Single Team -->
<!--                                        <h3 style="font-weight: normal">CEO &amp; Founder</h3>-->
                                        <h3 style="font-weight: normal">Rayyan Cristobal Maik</h3>
                                        <p class="mt-3">
                                            Rayyan Cristobal, earliest digital currency pioneer and evangelist in the Asia-Pacific region, spread digital currency and mining in the Asia-Pacific since 2011.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-end mt-5">
                                <div class="col-12 col-md-3 order-lg-last order-md-first">
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-2.png" alt="">
                                            <!-- Team Overlay -->
<!--                                            <div class="team-overlay">-->
<!--                                                <h5 class="team-name text-white">Jassica William</h5>-->
<!--                                                <h6 class="team-post text-white mt-2 mb-3">Co-Founder</h6>-->
<!--                                                &lt;!&ndash; Team Icons &ndash;&gt;-->
<!--                                                <div class="team-icons">-->
<!--                                                    <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>-->
<!--                                                    <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>-->
<!--                                                    <a class="p-2" href="#"><i class="fab fa-google-plus-g"></i></a>-->
<!--                                                    <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>-->
<!--                                                </div>-->
<!--                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-9 text-lg-right">
                                    <div class="img-txt">
                                        <!-- Single Team -->
<!--                                        <h3 style="font-weight: normal">CTO &amp; Co-Founder</h3>-->
                                        <h3 style="font-weight: normal">Bo Yuan Brady</h3>
                                        <p class="mt-3 text-lg-left">
                                            Bo yuan Brady dove headfirst into cryptocurrency and, in 2013, was part of the team to launch Asia-Pacific’s first cryptocurrency wallet. Before then, he had been a platform engineer at Google and Facebook.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section team-area team-style-two overflow-hidden ptb_100 bg-colored">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <!-- Section Heading -->
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="section-heading text-center mt-lg-5">
                                <h2 class="text-capitalize">Relai's agencies</h2>
                                <p class="d-none d-sm-block mt-4">
                                    Relai works closely with a handful of market-leading agencies to help us improve our brand, product, and reach.
                                </p>
                                <p class="d-block d-sm-none mt-4">
                                    Relai works closely with a handful of market-leading agencies to help us improve our brand, product, and reach.
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-8">

                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <!-- Single Team -->
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-3.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10">
                                    <div class="img-txt">
                                        <!-- Single Team -->
                                        <h5 style="font-weight: normal">BITCOIN / LIGHTNING DEVELOPEMENT</h5>
                                        <h6>Gabriel Comte</h6>
                                        <p class="mt-3" style="font-weight: normal">FROM PUZZLE ITC</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-end mt-2">
                                <div class="col-12 col-md-2 order-lg-last order-md-first">
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-4.png" alt="">
                                            <!-- Team Overlay -->
                                            <!--                                            <div class="team-overlay">-->
                                            <!--                                                <h5 class="team-name text-white">Jassica William</h5>-->
                                            <!--                                                <h6 class="team-post text-white mt-2 mb-3">Co-Founder</h6>-->
                                            <!--                                                &lt;!&ndash; Team Icons &ndash;&gt;-->
                                            <!--                                                <div class="team-icons">-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-google-plus-g"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>-->
                                            <!--                                                </div>-->
                                            <!--                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10 text-lg-right">
                                    <div class="img-txt">
                                        <!-- Single Team -->
                                        <h4 style="font-weight: normal">MARKETING AGENCY</h4>
                                        <h5>Kuble AG</h5>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <!-- Single Team -->
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-5.jpeg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10">
                                    <div class="img-txt">
                                        <!-- Single Team -->
                                        <h4 style="font-weight: normal">BACKEND DEVELOPMENT</h4>
                                        <h5>Robert Pupel</h5>
                                        <h6 class="mt-3" style="font-weight: normal">FROM DEVS GROUP</h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-end mt-2">
                                <div class="col-12 col-md-2 order-lg-last order-md-first">
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-6.png" alt="">
                                            <!-- Team Overlay -->
                                            <!--                                            <div class="team-overlay">-->
                                            <!--                                                <h5 class="team-name text-white">Jassica William</h5>-->
                                            <!--                                                <h6 class="team-post text-white mt-2 mb-3">Co-Founder</h6>-->
                                            <!--                                                &lt;!&ndash; Team Icons &ndash;&gt;-->
                                            <!--                                                <div class="team-icons">-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-google-plus-g"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>-->
                                            <!--                                                </div>-->
                                            <!--                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10 text-lg-right">
                                    <div class="img-txt">
                                        <!-- Single Team -->
                                        <h4 style="font-weight: normal">BRANDING AGENCY</h4>
                                        <h5>Reach Partners</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section team-area team-style-two overflow-hidden ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <!-- Section Heading -->
                    <div class="row">
                        <div class="col-12 col-md-8">

                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <!-- Single Team -->
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-7.jpg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10">
                                    <div class="img-txt">
                                        <!-- Single Team -->
                                        <h5 style="font-weight: normal">FULLSTACK DEVELOPMENT</h5>
                                        <h6>Piyush Gupta</h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-end mt-2">
                                <div class="col-12 col-md-2 order-lg-last order-md-first">
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-8.jpeg" alt="">
                                            <!-- Team Overlay -->
                                            <!--                                            <div class="team-overlay">-->
                                            <!--                                                <h5 class="team-name text-white">Jassica William</h5>-->
                                            <!--                                                <h6 class="team-post text-white mt-2 mb-3">Co-Founder</h6>-->
                                            <!--                                                &lt;!&ndash; Team Icons &ndash;&gt;-->
                                            <!--                                                <div class="team-icons">-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-google-plus-g"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>-->
                                            <!--                                                </div>-->
                                            <!--                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10 text-lg-right">
                                    <div class="img-txt">
                                        <!-- Single Team -->
                                        <h5 style="font-weight: normal">FRONTEND DEVELOPMENT</h5>
                                        <h6>José Cañizares</h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <!-- Single Team -->
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-9.jpeg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10">
                                    <div class="img-txt">
                                        <!-- Single Team -->
                                        <h5 style="font-weight: normal">QA / TEST ENGINEER</h5>
                                        <h6>Sergii Sichynskyi</h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-md-4">
                            <div class="section-heading text-center mt-lg-5">
                                <h2 class="text-capitalize">Relai's development</h2>
                                <p class="d-none d-sm-block mt-4">
                                    Our development team includes experienced mobile app developers and a veteran Bitcoin software engineer
                                </p>
                                <p class="d-block d-sm-none mt-4">
                                    Our development team includes experienced mobile app developers and a veteran Bitcoin software engineer
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section team-area team-style-two overflow-hidden ptb_100 bg-colored">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <!-- Section Heading -->
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="section-heading text-center mt-lg-5">
                                <h2 class="text-capitalize">Relai's product</h2>
                                <p class="d-none d-sm-block mt-4">
                                    Our product team comes from a diverse background, ranging from web development to product design.
                                </p>
                                <p class="d-block d-sm-none mt-4">
                                    Our product team comes from a diverse background, ranging from web development to product design.
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-8">

                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <!-- Single Team -->
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-10.jpeg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10">
                                    <div class="img-txt">
                                        <!-- Single Team -->
                                        <h5 style="font-weight: normal">PRODUCT MANAGER</h5>
                                        <h6>Alain Imhof</h6>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-end mt-2">
                                <div class="col-12 col-md-2 order-lg-last order-md-first">
                                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                                        <!-- Team Thumb -->
                                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                                            <img src="../../assets/parent/img/avatar/avatar-11.jpg" alt="">
                                            <!-- Team Overlay -->
                                            <!--                                            <div class="team-overlay">-->
                                            <!--                                                <h5 class="team-name text-white">Jassica William</h5>-->
                                            <!--                                                <h6 class="team-post text-white mt-2 mb-3">Co-Founder</h6>-->
                                            <!--                                                &lt;!&ndash; Team Icons &ndash;&gt;-->
                                            <!--                                                <div class="team-icons">-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-google-plus-g"></i></a>-->
                                            <!--                                                    <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>-->
                                            <!--                                                </div>-->
                                            <!--                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-10 text-lg-right">
                                    <div class="img-txt">
                                        <!-- Single Team -->
                                        <h5 style="font-weight: normal">OPERATIONS MANAGER</h5>
                                        <h6>Michael Güttinger</h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section team-area team-style-two overflow-hidden ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2 class="text-capitalize">Our Sponsors</h2>
                        <p class="d-none d-sm-block mt-4">
                            Minimalism gives focus. Focus gives Prios. Prios give speed. Speed wins!”
                        </p>
                        <p class="d-block d-sm-none mt-4">
                            Minimalism gives focus. Focus gives Prios. Prios give speed. Speed wins!”
                        </p>
                    </div>
                </div>
            </div>
            <div class="app-screenshots">
                <div class="">
                    <!-- Single Team -->
                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                        <!-- Team Thumb -->
                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                            <img src="../../assets/parent/img/avatar/avatar-12.png" alt="">
                        </div>
                    </div>
                    <div class="text-center mt-2">
                        <h3>Redalpine Capital</h3>
                        <h4>INVESTOR</h4>
                    </div>
                </div>
                <div class="">
                    <!-- Single Team -->
                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                        <!-- Team Thumb -->
                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                            <img src="../../assets/parent/img/avatar/avatar-13.jpg" alt="">
                        </div>
                    </div>
                    <div class="text-center mt-2">
                        <h3>Polytech Ventures</h3>
                        <h4>INVESTOR</h4>
                    </div>
                </div>
                <div class="">
                    <!-- Single Team -->
                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                        <!-- Team Thumb -->
                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                            <img src="../../assets/parent/img/avatar/avatar-14.png" alt="">
                        </div>
                    </div>
                    <div class="text-center mt-2">
                        <h3>Fulgur Ventures</h3>
                        <h4>INVESTOR</h4>
                    </div>
                </div>
                <div class="">
                    <!-- Single Team -->
                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                        <!-- Team Thumb -->
                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                            <img src="../../assets/parent/img/avatar/avatar-15.png" alt="">
                        </div>
                    </div>
                    <div class="text-center mt-2">
                        <h3>ACE Investments</h3>
                        <h4>INVESTOR</h4>
                    </div>
                </div>
                <div class="">
                    <!-- Single Team -->
                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                        <!-- Team Thumb -->
                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                            <img src="../../assets/parent/img/avatar/avatar-16.jpg" alt="">
                        </div>
                    </div>
                    <div class="text-center mt-2">
                        <h3>Giacomo Zucco</h3>
                        <h4>INVESTOR</h4>
                    </div>
                </div>
                <div class="">
                    <!-- Single Team -->
                    <div class="single-team text-center overflow-hidden m-2 m-lg-0">
                        <!-- Team Thumb -->
                        <div class="team-thumb d-inline-block position-relative overflow-hidden">
                            <img src="../../assets/parent/img/avatar/avatar-17.png" alt="">
                        </div>
                    </div>
                    <div class="text-center mt-2">
                        <h3>PUZZLE ITC</h3>
                        <h4>INVESTOR</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== Height Emulator Area Start ======-->
    <div class="height-emulator d-none d-lg-block"></div>
    <!--====== Height Emulator Area End ======-->

</div>
</template>

<script>
export default {
    name: 'About',
};
</script>

<style scoped>
.breadcrumb li a,
.breadcrumb li{
    color: white;
}
.single-team{
    border-radius: 50%;
    /*width: 200px;*/
    /*height:200px;*/
    box-shadow: 1px -1px 5px rgba(0,0,0,.5)
}

.team-thumb{
    border-radius: 50%;
    /*width: 100%;*/
    /*height:100%;*/
    padding:0;
}

.section.team-area .section-heading h2{
    font-size: 2em;
}
.bg-colored{
    background: rgb(30,80,168);
    background: linear-gradient(90deg, rgba(30,80,168,1) 0%, rgba(18,50,107,1) 100%);
}
section h1,
section h2,
section h3,
section h4,
section h5,
section h6,
section p{
    color: #12326B;
}
.bg-colored h1,
.bg-colored h2,
.bg-colored h3,
.bg-colored h4,
.bg-colored h5,
.bg-colored h6,
.bg-colored p{
    color: #FFFFFF;
}
.bg-colored .section-heading > h2::after{
    background-color: #FFFFFF;
}
@media screen and (min-width: 320px){

}
@media screen and (min-width: 768px){
    .img-txt{
        margin-top: 30px
    }
}
</style>